<template>
  <div
    class="company-container"
    :style="{ 'box-shadow': boxShadow }"
    @mouseover="mouseIn"
    @mouseleave="mouseOut"
  >
    <router-link :to="{ name: isMobileApp ? 'tracker' : 'company', params: { company: company.slug } }">
      <div class="content">
        <div class="logo">
          <img
            v-if="company.primary_logo_large"
            :src="company.primary_logo_large"
          />
          <font-awesome-icon
            v-else
            icon="building"
            :style="{
              color: company.color
                ? company.color + ' !important'
                : '#303133 !important',
            }"
          ></font-awesome-icon>
        </div>
        <div class="name">
          <span
            :style="{
              color: company.color
                ? company.color + ' !important'
                : '#303133 !important',
            }"
          >
            {{ company.name }}
          </span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<style scoped>
</style>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CompanyCard",

  mixins: [],
  components: {},

  props: {
    company: {
      required: true,
    },
  },
  data() {
    return {
      defaultColor: "0, 0, 0",
      hoverColor: "",
    }
  },
  computed: {
    boxShadow() {
      return "0 2px 2px 0  rgba(" + this.hoverColor + ")"
    },
    ...mapGetters({
      isMobileApp: "auth/isMobileApp",
    }),
  },
  watch: {},

  methods: {
    hexToRgb(hex) {
      if (hex === null)
        return {
          r: 0,
          g: 0,
          b: 0,
        }
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b
      })

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : {
            r: 0,
            g: 0,
            b: 0,
          }
    },
    mouseIn() {
      if (!this.company.color) {
        this.hoverColor = this.defaultColor + ", .5"
      }
      let rgb = this.hexToRgb(this.company.color)
      if (rgb) {
        this.hoverColor = rgb.r + ", " + rgb.g + ", " + rgb.b + ", .5"
      }
    },
    mouseOut() {
      this.hoverColor = this.defaultColor + ", .1"
    },
  },

  created() {},
  mounted() {
    this.hoverColor = this.defaultColor + ", .1"
  },
}
</script>
