<template>
  <div>
    <el-card v-if="error" style="text-align: center">
      We're sorry, but you do not have access to view any companies. If you
      believe this is an error please reach out to your account representative.
    </el-card>
    <div v-else>
      <div class="search-container">
        <div :class="{'input-container': isMobileApp}">
          <el-input
            v-model="search"
            prefix-icon="el-icon-search"
            @input="handleSearch"
            :style="{ width: isMobileApp ? '100%' : '300px' }"
            size="small"
            placeholder="Search By Company Name..."
          />
        </div>
        <div class="view-toggle" :class="{'button-container': isMobileApp}">
          <el-button size="small" icon="el-icon-refresh" @click="refreshPage()" type="warning"></el-button>
          <div class="view-toggle" v-if="isMobileApp">
            <el-radio-group v-model="viewMode" size="small">
              <el-radio-button label="tile" value="tile">
                <font-awesome-icon icon="grip-horizontal" />
              </el-radio-button>
              <el-radio-button label="list" value="list">
                <font-awesome-icon icon="list" />
              </el-radio-button>
            </el-radio-group>
          </div>
        </div>
      </div>

      <div v-if="isMobileApp" class="companies-container" :class="viewMode === 'list' ? 'list-view' : 'tile-view'">
        <company-card
          v-for="company in allCompanies"
          :company="company"
          :key="`${company.id}-${company.slug}`"
        >
        </company-card>
      </div>

      <div v-else class="companies-container usual-view">
        <company-card v-for="company in allCompanies" :company="company" :key="company.slug"></company-card>
      </div>

      <el-pagination v-if="!isMobileApp" class="pagination" layout="prev, pager, next" @current-change="handleCurrentChange"
        :current-page="currentPage" :page-size="20" :total="totalItems" />

      <div v-loading="loading"></div>
    </div>
  </div>
</template>

<style lang="scss">
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.input-container {
  flex: 3;
  margin-right: 0.5rem;
}

.button-container {
  display: flex;
  align-items: center;
}

.view-toggle {
  margin-left: 0.5rem;
}

.companies-container {
  &.usual-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .company-container {
      width: calc(25% - 10px);
      margin-bottom: 1rem;
    }
  }
  &.tile-view {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .company-container {
      width: calc(50% - 10px);
      margin-bottom: 1rem;
    }
  }

  &.list-view {
    display: flex;
    flex-direction: column;

    .company-container {
      width: 100%;
      margin-bottom: 0.5rem;

      .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #fafafa;
        .logo {
          flex: 0 0 auto;
        }
        .name {
          text-align: left;
          justify-content: start;
          margin-left: 0.5rem;
          flex-grow: 1;
          span {
            margin: 0;
          }
        }
        .logo {
          width: 50px;
          height: 60px;
          >img,
          svg {
            width: 50px;
            height: 60px;
            object-fit: contain;
          }
        }
      }
    }
  }
}

.companies-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  .company-container {
    width: calc(25% - 10px);
    background-color: #fefefe;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: box-shadow 0.2s;

    .content {
      display: flex;
      flex-direction: column;
      height: 100%;

      .logo {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin: 0.5rem;
        position: relative;

        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (1 / 1) * 100%;
        }

        >img,
        svg {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: auto;
          max-height: 180px;
          height: 100%;
          margin: auto;
        }
      }

      .name {
        display: flex;
        justify-content: center;
        font-size: 1.125rem;
        letter-spacing: 0.05rem;
        flex-grow: 1;
        align-items: center;
        background: #fafafa;
        text-align: center;

        span {
          margin: 0.5rem;
        }
      }
    }
  }
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background: #E6A23C !important;
}

@media only screen and (max-width: 768px) {
  .companies-container {
    .company-container {
      width: calc(50% - 10px);
    }
  }
}
</style>

<script>
import CompanyCard from "@/partials/Home/CompanyCard"
import { mapGetters, mapActions } from "vuex"
import { debounce } from 'lodash'
export default {
  name: "HomePage",

  components: {
    CompanyCard,
  },

  data() {
    return {
      error: false,
      currentPage: 1,
      search: '',
      loading: true,
      viewMode: 'tile',
      allCompanies: [],
    }
  },
  computed: {
    isMobileApp() {
      const isMobileApp = localStorage.getItem('isMobileApp');
      if (isMobileApp !== null) {
        this.$store.dispatch('auth/updateIsMobileApp', isMobileApp === 'true');
      }
      return this.$store.getters['auth/isMobileApp'];
    },
    ...mapGetters({
      companies: "companies/list",
      totalItems: "companies/totalItems",
    }),
  },

  watch: {
    companies(newCompanies) {
      const newUniqueCompanies = newCompanies.filter(company => {
        return !this.allCompanies.some(existingCompany => existingCompany.slug === company.slug);
      });

      if (newUniqueCompanies.length) {
        this.allCompanies = [...this.allCompanies, ...newUniqueCompanies];
      }
    }
  },

  mounted() {
    this.fetchData(this.currentPage);

    if (this.isMobileApp) {
      window.addEventListener('scroll', this.handleScroll);
    }

    if (this.companies && !this.companies.length) {
      this.error = true;
    } else if (this.companies && this.companies.length === 1 && !this.isMobileApp) {
      this.$router.replace({
        name: "company",
        params: { company: this.companies[0].slug },
      });
    }
  },

  beforeDestroy() {
    if (this.isMobileApp) {
      window.removeEventListener('scroll', this.handleScroll);
    }
  },

  methods: {
    ...mapActions('companies', ['refresh', 'batchSet']),

    async handleScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      const bottomPosition = document.body.offsetHeight - 1;

      if (scrollPosition >= bottomPosition && !this.loading) {
        this.currentPage += 1;
        await this.fetchData(this.currentPage);
      }
    },

    async fetchData(page = 1, search = this.search) {
      this.loading = true;

      try {
        await this.refresh({ page, query: search });
      } finally {
        await this.batchSet(this.allCompanies);
        await new Promise(r => setTimeout(r, 100));
        this.loading = false;
      }
    },

    handleCurrentChange(page) {
      this.currentPage = page;
      this.allCompanies = [];
      this.fetchData(page);
    },

    handleSearch: debounce(function () {
      this.currentPage = 1;
      this.allCompanies = [];
      this.fetchData(this.currentPage);
    }, 500),

    refreshPage() {
      this.search = '';
      this.currentPage = 1;
      this.allCompanies = [];
      this.fetchData(this.currentPage, this.search);
    },
  }
}
</script>
